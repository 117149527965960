<template>
  <div class="container">
    <section class="section">
      <div class="content has-text-centered is-marginless">
        <h1>Manage Schools</h1>
      </div>
      <!-- <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo"> -->
      <div class="text-center is-12">
        <div v-if="districtData && districtData.name" class="box box-info" style="color:black">
          <div class="box-header content">
            <div class="text-center">
              <h2 class="">
                District: {{ districtData.name }}
              </h2>
            </div>
            <div class="box-tools pull-right">
              <router-link v-if="$store.state.user.district_role == 'district_admin' || $store.state.user.district_role == 'school_admin'" to="add-school" type="button" class="button" title="Add School to District">
                <span class="icon"><i class="mdi mdi-plus" /></span> <span>Add School</span>
              </router-link>
              <!-- <router-link type="button" class="btn btn-box-tool" v-if="$store.state.user.school"  to="/" :title="'Back to ' + $store.state.user.school.school_name"><i class="fas fa-chevron-right"></i></router-link> -->
            </div>

            <a v-if="districtData.clever_portal_url" class="button" :href="districtData.clever_portal_url" target="_blank"><span class="icon"><img src="/img/clevericon.png"></span> <span>Clever Portal</span></a>
            <button v-if="$store.state.user.district_role == 'district_admin' && districtData.clever_oid" class="button" @click="syncDistrict">
              <span class="icon"><img src="/img/clevericon.png"></span> <span>Sync District</span> <span class="icon"><i class="fas fa-sync" /></span>
            </button>
          </div>
        </div>
        <div v-else-if="!$store.state.user.district_id" class="columns">
          <div class="column is-12">
            <div class="box-tools pull-right">
              <router-link to="add-school" type="button" class="button" title="Add School">
                <span class="icon"><i class="mdi mdi-plus" /></span> <span>Add School</span>
              </router-link>
              <!-- <router-link type="button" class="btn btn-box-tool" v-if="$store.state.user.school"  to="/" :title="'Back to ' + $store.state.user.school.school_name"><i class="fas fa-chevron-right"></i></router-link> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="districtData" class="columns is-multiline">
        <div v-for="school in districtData.schools" :key="'sch-' + school.id" class="column is-4">
          <div class="box box-white">
            <div class="content">
              <h3>{{ school.school_name }}</h3>
              <p><small><i>ID: {{ school.id }}</i></small></p>
              <p v-if="districtData.id && school.district_id == districtData.id" class="has-text-info">
                <i>District: {{ districtData.name }}</i>
              </p>
              <p>
                <small>{{ school.school_address1 }}<br>
                  <span v-if="school.school_address2">{{ school.school_address2 }}<br></span>
                  {{ school.school_town }}<br>
                  {{ school.school_county }}<br>
                  {{ school.school_postcode }}</small>
              </p>
              <button class="button is-link" @click="manageSchool(school.id)">
                Manage
              </button>
              <button v-if="school.clever_oid" class="button" @click="syncSchool(school.clever_oid)">
                <span class="icon"><img src="/img/clevericon.png"></span> <span>Sync School</span> <span class="icon"><i class="fas fa-sync" /></span>
              </button>

              <p v-if="!school.admin" style="margin: 5px 0;">
                <button class="button is-danger" @click="leaveSchool(school.id)">
                  Leave School
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-6">
          <div class="box box-white">
            <div class="content">
              <h3>Join School</h3>
              <b-field position="is-centered" expanded>
                <b-input v-model="schoolCode" placeholder="Enter school code to join school" expanded />
                <p class="control">
                  <button class="button is-link" @click="joinSchoolWithCode">
                    Join
                  </button>
                </p>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'

export default {
  name: 'DistrictView',
  data (router) {
    return {
      districtData: null,
      schoolCode: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      // redirect if no permission
      if (!this.$store.state.user.all_schools) {
        console.log(1)
        this.$router.push('/')
      }
      if (!this.$store.state.user.all_schools && this.$store.state.user.district_role != 'district_admin') {
        console.log(2)
        this.$router.push('/')
      }
      if (this.$store.state.user.school) {
        console.log(3)
        this.$router.push('/')
      }

      if (this.$store.state.user.school && !this.$store.state.user.school.teacher) {
        window.location.replace('https://www.edshed.com/menu')
      }
      // load district data
      this.getDistrictData()
    })
  },
  methods: {
    getDistrictData () {
      request('GET', 'users/me/district', null)
        .then((response) => {
          const data = response.data
          this.districtData = data.district
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    syncDistrict () {
      request('POST', 'users/me/district/sync', null)
        .then((response) => {
          this.$buefy.toast.open({ message: 'Success', type: 'is-success' })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    syncSchool (schoolOid) {
      request('POST', 'schools/' + schoolOid + '/cleversync', null)
        .then((response) => {
          this.$buefy.toast.open({ message: 'Success', type: 'is-success' })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    manageSchool (schoolId) {
      request('POST', 'users/me/manageschool', { schoolId }, this.$store.state.tokan)
        .then((response) => {
          const data = response.data
          console.log(data)
          if (data.user) {
            const token = data.user.token
            this.$store.commit('SET_USER', data.user)
            this.$store.commit('SET_TOKEN', token)

            // console.log('token: ' + token)
            // console.log('user: ' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
              window.localStorage.setItem('token', token)
            }

            this.$router.push('/')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    joinSchoolWithCode () {
      this.loadingSchoolCode = true
      request('POST', 'users/me/joinschool', { code: this.schoolCode })
        .then((response) => {
          const data = response.data
          // save user
          this.loadingSchoolCode = false
          if (data.user) {
            this.$store.commit('SET_USER', data.user)

            // console.log('user: ' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }
            console.log('redirecting')
            this.$router.push('/')
          } else {
            this.schoolCode = ''
            alert('Code not recognised. Please check with your school admin to ensure your code is correct.')
          }
        })
        .catch((error) => {
          console.log(error)
          this.loadingSchoolCode = false

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    leaveSchool (schoolId) {
      const c = confirm('Are you sure you want to leave this school? N.B. You cannot leave a school whilst being a school admin.')
      if (c) {
        request('DELETE', 'users/me/school/membership', { school: schoolId })
          .then((response) => {
            const data = response.data
            console.log(data)
            if (data.error) {
              alert(data.error)
              return
            }
            this.getDistrictData()
          })
          .catch((error) => {
            console.log(error)
            this.loadingSchoolCode = false

            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }

            this.response = 'Details incorrect'
          })
      }
    }
  }
}
</script>
